import React from 'react'

import { Heading, QuestionIcon } from '@te-digi/styleguide'

const IconUsageExample3 = () => (
  <Heading
    icon={<QuestionIcon />}
    level={2}
  >
    Heading with Icon
  </Heading>
)

export { IconUsageExample3 }
