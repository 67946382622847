import React from 'react'

import { Inline, LocationIcon } from '@te-digi/styleguide'

const IconUsageExample5 = () => (
  <Inline alignItems="center">
    <LocationIcon
      block
      size="lg"
    />
    Tampere
  </Inline>
)

export { IconUsageExample5 }
