import React from 'react'

import { Button, Inline, XIcon } from '@te-digi/styleguide'

const IconUsageExample2 = () => (
  <Inline
    gap="md"
    wrap="wrap"
  >
    <Button iconLeft={<XIcon />}>Teksti</Button>
    <Button iconRight={<XIcon />}>Teksti</Button>
  </Inline>
)

export { IconUsageExample2 }
