import { Paragraph } from '@te-digi/styleguide'
import React, { ComponentProps, ComponentType, useState } from 'react'
import styled from 'styled-components'
import tokens from '@te-digi/styleguide-tokens'

import * as StyleGuide from '@te-digi/styleguide'

import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'
import IconHTMLExample from '../../examples/html/IconHTMLExample'
import { IconUsageExample1 } from '../../examples/react/IconUsageExample1'
import { IconUsageExample2 } from '../../examples/react/IconUsageExample2'
import { IconUsageExample3 } from '../../examples/react/IconUsageExample3'
import { IconUsageExample4 } from '../../examples/react/IconUsageExample4'
import { IconUsageExample5 } from '../../examples/react/IconUsageExample5'
import { IconUsageExample6 } from '../../examples/react/IconUsageExample6'

const {
  BulletFillIcon,
  BulletIcon,
  Box,
  ChevronDownCircleFillIcon,
  ChevronDownIcon,
  ChevronUpCircleFillIcon,
  ChevronUpIcon,
  Col,
  FormGroup,
  HomeIcon,
  InfoCircle2FillIcon,
  InfoCircle2Icon,
  Inline,
  Input,
  Row,
  Select,
  Spacer,
  SpeechBubbleFillIcon,
  SpeechBubbleIcon,
  Stack,
  Text,
  TePalvelutFillIcon
} = StyleGuide

const StyledCol = styled(Col)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: ${tokens.space.lg};
  padding-top: ${tokens.space.lg};
  text-align: center;
`

const SIZES: ComponentProps<typeof Select>['options'] = [
  { label: 'md' },
  { label: 'lg' },
  { label: 'xl' },
  { label: 'xxl' },
  { label: 'xxxl' },
  { label: 'layout-lg' },
  { label: 'layout-xl' }
]

const iconComponents = Object.keys(StyleGuide)
  .filter(name => name !== 'Icon' && name.endsWith('Icon'))
  .map(name => StyleGuide[name as keyof typeof StyleGuide]) as ComponentType<
  ComponentProps<typeof HomeIcon>
>[]

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [size, setSize] = useState<ComponentProps<typeof Select>['value']>(
    SIZES[1]
  )
  const [search, setSearch] = useState('')

  return (
    <Content
      heading="Ikonit"
      components={[{ component: HomeIcon }]}
    >
      <Section>
        <Box color="light">
          <Row>
            <Col
              sm={7}
              md={12}
              lg={7}
            >
              <FormGroup noMargin>
                <Input
                  clearable
                  label="Haku"
                  onChange={event => setSearch(event.target.value)}
                  value={search}
                />
              </FormGroup>
            </Col>
            <Col
              sm={5}
              md={12}
              lg={5}
            >
              <Spacer
                marginTop={{ xs: 'lg', sm: 'none', md: 'lg', lg: 'none' }}
              >
                <FormGroup noMargin>
                  <Select
                    label="Koko"
                    onChange={value => setSize(value)}
                    options={SIZES}
                    value={size}
                  />
                </FormGroup>
              </Spacer>
            </Col>
          </Row>
        </Box>
        <Row>
          {iconComponents
            .filter(icon =>
              icon.displayName?.toLowerCase().includes(search.toLowerCase())
            )
            .sort()
            .map(Icon => (
              <StyledCol
                xs={4}
                lg={3}
                key={Icon.displayName}
              >
                <Icon
                  size={size?.label as ComponentProps<typeof Icon>['size']}
                />
                <Spacer marginTop="xs">
                  <Code>
                    <Text size="sm">&lt;{Icon.displayName} /&gt;</Text>
                  </Code>
                </Spacer>
              </StyledCol>
            ))}
        </Row>
      </Section>

      <Section title="Saavutettavuus">
        <Paragraph>
          Ikonit ovat usein kuvituksellisia, jolloin niitä ei ole tarpeen lukea
          ruudunlukijalla. Kuitenkin joissakin tapauksissa ikonilla halutaan
          ilmaista jotakin, joka ei käy ilmi tekstistä. Tällöin komponentille
          tulee määritellä ruudunlukijalle luettava (kieliversioitu) teksti{' '}
          <Code>ariaLabel</Code>illa, esim.{' '}
          <Code>AlertIcon ariaLabel="virhe"</Code>.
        </Paragraph>
      </Section>

      <Section title="Esimerkkejä">
        <Playground>
          <Stack gap="md">
            <Inline
              alignItems="end"
              gap="md"
            >
              <HomeIcon />
              <HomeIcon size="lg" />
              <HomeIcon size="xl" />
              <HomeIcon size="xxl" />
              <HomeIcon size="xxxl" />
              <HomeIcon size="layout-lg" />
              <HomeIcon size="layout-xl" />
            </Inline>
            <Inline
              alignItems="end"
              gap="md"
            >
              <HomeIcon
                size="lg"
                color="primary"
              />
              <HomeIcon
                size="lg"
                color="secondary"
              />
              <HomeIcon
                size="lg"
                color="success"
              />
              <HomeIcon
                size="lg"
                color="warning"
              />
              <HomeIcon
                size="lg"
                color="danger"
              />
              <HomeIcon
                size="lg"
                color="dark"
              />
              <TePalvelutFillIcon
                size="lg"
                color="brand"
              />
            </Inline>
          </Stack>
        </Playground>
        <Playground format="html">
          <>
            <div className="flex align-items-end mb-md">
              <div className="mr-md">
                <IconHTMLExample name="HomeIcon" />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  name="HomeIcon"
                  size="xl"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  name="HomeIcon"
                  size="xxl"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  name="HomeIcon"
                  size="xxxl"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  name="HomeIcon"
                  size="layout-lg"
                />
              </div>
              <div>
                <IconHTMLExample
                  name="HomeIcon"
                  size="layout-xl"
                />
              </div>
            </div>
            <div className="flex align-items-end">
              <div className="mr-md">
                <IconHTMLExample
                  color="primary"
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  color="secondary"
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  color="success"
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  color="warning"
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  color="danger"
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  color="dark"
                  name="HomeIcon"
                  size="lg"
                />
              </div>
              <div className="mr-md">
                <IconHTMLExample
                  color="brand"
                  name="TePalvelutFillIcon"
                  size="lg"
                />
              </div>
            </div>
          </>
        </Playground>

        <Paragraph>
          Käytä default-vihreää täytetyille ikoneille, ja tummempaa vihreän
          sävyä viivaikoneille paremman kontrastin aikaansaamiseksi.
        </Paragraph>

        <Playground>
          <Inline
            alignItems="end"
            gap="xl"
          >
            <SpeechBubbleFillIcon
              size="xl"
              color="success"
            />
            <SpeechBubbleIcon
              size="xl"
              color="success-dark"
            />

            <InfoCircle2FillIcon
              size="xl"
              color="success"
            />
            <InfoCircle2Icon
              size="xl"
              color="success-dark"
            />

            <ChevronDownCircleFillIcon
              size="xl"
              color="success"
            />
            <ChevronDownIcon
              size="xl"
              color="success-dark"
            />

            <ChevronUpCircleFillIcon
              size="xl"
              color="success"
            />
            <ChevronUpIcon
              size="xl"
              color="success-dark"
            />

            <BulletFillIcon
              size="xl"
              color="success"
            />
            <BulletIcon
              size="xl"
              color="success-dark"
            />
          </Inline>
        </Playground>

        <Playground example={IconUsageExample1} />
        <Playground example={IconUsageExample2} />
        <Playground example={IconUsageExample3} />
        <Playground example={IconUsageExample4} />
        <Playground example={IconUsageExample5} />
        <Playground example={IconUsageExample6} />
      </Section>
    </Content>
  )
}

export default Page
