import React from 'react'

import { BookIcon, Bullet, Inline, Link } from '@te-digi/styleguide'

const IconUsageExample1 = () => (
  <Inline
    alignItems="end"
    gap="xs"
  >
    <Bullet
      color="primary"
      icon={<BookIcon />}
    />
    <Link
      external
      href="https://www.google.com"
    >
      Linkki
    </Link>
  </Inline>
)

export { IconUsageExample1 }
